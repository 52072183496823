<template>
  <div>
    <vx-card>
      <!-- <div v-if="$apollo.loading">
        <p>Cargando...</p>
      </div> -->
      <div>
        <ValidationObserver
          ref="validateFormFranchiseUpdate"
          v-slot="{ invalid }"
        >
          <div class="vx-row">
            <div class="vx-col sm:w-1/3 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:150"
                name="Nombre"
              >
                <vs-input
                  class="w-full"
                  label="Nombre"
                  placeholder="Nombre"
                  v-model="formData.name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/3 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Latitud"
              >
                <vs-input
                  class="w-full"
                  label="Latitud"
                  placeholder="Latitud Eje: 7.12539"
                  v-model="formData.latitude"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/3 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Longitud"
              >
                <vs-input
                  class="w-full"
                  label="Longitud"
                  v-model="formData.longitude"
                  placeholder="Longitud Eje: -73.1198"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Departamento"
                vid="department"
              >
                <vs-select
                  class="w-full"
                  label="Departamento"
                  placeholder="Departamento"
                  autocomplete
                  v-model="selectedDepartment"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.id"
                    :text="item.name"
                    v-for="(item, index) in departments"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Ciudad"
                vid="city"
              >
                <vs-select
                  class="w-full"
                  label="Ciudad"
                  v-model="selectedCity"
                  placeholder="Ciudad"
                  autocomplete
                >
                  <vs-select-item
                    :key="index"
                    :value="item.id"
                    :text="item.name"
                    v-for="(item, index) in cities"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-10">
            <div class="vx-col w-full flex justify-end">
              <vs-button
                class="mr-3 mb-3"
                :disabled="invalid || !selectedCity"
                @click="saveNeighbor"
              >
                Guardar
              </vs-button>
              <vs-button type="border" class="mb-3" @click="$router.go(-1)">
                Descartar
              </vs-button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { CITIES, DEPARTAMENTS, NEIGHBORHOOD_BY_ID } from '@/graphql/queries';
import { UPDATE_CREATE_NEIGHBORHOOD } from '@/graphql/mutations';
export default defineComponent({
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
  },
  setup(props, { root }) {
    const selectedDepartment = ref();
    const selectedCity = ref();
    const { onResult: onNeighborhoodResult } = useQuery(
      NEIGHBORHOOD_BY_ID,
      {
        id: root.$route.params.id,
      },
      {
        enabled: props.mode === 'edit',
      }
    );

    onNeighborhoodResult(({ data }) => {
      if (data) {
        selectedDepartment.value = data.neighborhoodById.city.department.id;
        selectedCity.value = data.neighborhoodById.city.id;
        formData.name = data.neighborhoodById.name;
        formData.latitude = data.neighborhoodById.coordinates[0];
        formData.longitude = data.neighborhoodById.coordinates[1];
      }
    });

    const { result: citiesResult } = useQuery(CITIES, {
      department_id: selectedDepartment,
    });
    const cities = useResult(citiesResult, [], (data) => data.cities);
    const { result: departmentsResult } = useQuery(DEPARTAMENTS);
    const departments = useResult(
      departmentsResult,
      [],
      (data) => data.departments
    );
    const formData = reactive({
      name: '',
      latitude: '',
      longitude: '',
    });

    const { mutate: updateOrCreateNeighborhood } = useMutation(
      UPDATE_CREATE_NEIGHBORHOOD
    );

    /**
     * Remueve el city_id cada vez que cambie el departamento
     */

    watch(selectedDepartment, (newValue, oldVal) => {
      if (newValue && oldVal !== undefined) {
        selectedCity.value = '';
      }
    });

    const saveNeighbor = async () => {
      const payload = {
        name: formData.name,
        coordinates: [formData.latitude, formData.longitude],
        city_id: selectedCity.value,
      };
      if (root.$route.params.id) {
        payload.id = root.$route.params.id;
      }

      await updateOrCreateNeighborhood({
        ...payload,
      });

      const mode = props.mode === 'edit' ? 'actualizado' : 'creado';
      root.$vs.notify({
        color: 'success',
        title: `Barrio ${mode}`,
        text: `El barrio fue correctamente ${mode}`,
      });
    };

    return {
      formData,
      cities,
      departments,
      selectedCity,
      selectedDepartment,
      saveNeighbor,
    };
  },
});
</script>

<style></style>
